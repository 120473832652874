import React from "react";
import FadeIn from "./FadeIn";


function Contact() {
  return (
    <div className="contact-section">
      <FadeIn>

        <div className="contact-container">

            <div className="contact-media">
                <div className="contact-title">Have something you want to talk about? <br/><span>Let's get in touch!</span> </div>
                <div className="contact-pic"></div>
            </div>
            <div className="contact-form">
                <form method="POST" action="https://api.web3forms.com/submit">

                    <input type="hidden" name="access_key" value="38dd02b0-7f60-4e60-bdda-9fd07501a184"/>

                    <input type="text" id="name" name="name" placeholder="Name" required/>

                    <input type="email" id="email" name="email" placeholder="Email" required/>

                    <input type="text" id="subject" name="subject" placeholder="Subject" required/>

                    <textarea id="message" name="message" placeholder="Write something.." required></textarea>

                    <button type="submit">Send</button>
                </form>
            </div>


        </div>

      </FadeIn>
    </div>
  );
}

export default Contact();